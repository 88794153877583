import React, { useState } from 'react';
import { navigate, Link } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../../components/Layout';
import CFS from '../../components/molecules/CFS';

import mondayConfig from '../../constants/monday-config';

import imgDir from '../../assets/images/pages/contacto/direction.svg';
import imgTel from '../../assets/images/pages/contacto/tel.svg';
import imgWha from '../../assets/images/pages/contacto/whatsapp.svg';
import imgClock from '../../assets/images/pages/contacto/clock.svg';
import imgMail from '../../assets/images/pages/contacto/email.svg';

import '../../assets/styles/pages/contacto.scss';

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
  comment: '',
};

const Cotacto = () => {
  const [formData, setFormData] = useState(EMPTY_FORM_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const MondayData = {
      BOARD_ID: mondayConfig.boardEleBarcelonaLeadsId,
      GROUP_ID: mondayConfig.groupId,
      itemName: `${formData.firstName} ${formData.lastName}`,
      values: {
        status_3: { label: 'Contacto web' }, // Source - Fuente
        status_5: mondayConfig.status, // Estatus
        first_name: formData.firstName, // Primer nombre
        last_name: formData.lastName, // Apellidos
        email9: formData.userEmail,
        comments: formData.comment, // Comentarios
        date5: mondayConfig.date, // contact date - Fecha contacto
      },
    };

    const response = await fetch('/api/monday', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(MondayData),
    });
    // addToMailchimp(formData.userEmail, MailchimpData);
    // downloadFile('brexit-guide.pdf', fileBrexitGuide);

    const jsonResponse = await response.json();

    if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
      setFormData(EMPTY_FORM_DATA);

      if (window?.fbq) {
        window.fbq('track', 'SubmitApplication');
      }

      navigate('/contactar/gracias');
    }
  };
  return (
    <Layout
      title="Contactar | ELE Barcelona"
      description="Puedes consultarnos online o en la escuela sobre nuestros cursos. Horario: lunes a viernes de 09:30 a 17:30. Dirección: C/ Valencia 275, piso 3, 08009, Barcelona."
    >
      <div className="contacto">
        <CFS
          title="Contáctanos"
          description="Responderemos a tu consulta en un plazo de 24 horas."
        />
        <section className="second-section c-section-py-80">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="block_l">
                  <form id="brexit" onSubmit={handleSubmit} className="form">
                    <h1 className="c-title-22 form__title">Mándanos un mensaje</h1>

                    <label className="form__first-name">
                      <p className="c-text-14 text-under">Primer nombre</p>
                      <input
                        className="input__first-name"
                        type="text"
                        placeholder="Pablo"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label className="form__last-name">
                      <p className="c-text-14 text-under">Apellidos</p>
                      <input
                        className="input__last-name"
                        type="text"
                        placeholder="González"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>

                    <label className="form__first-name email">
                      <p className="c-text-14 text-under">Correo electrónico</p>
                      <input
                        className="input__email"
                        type="email"
                        placeholder="example@gmail.com"
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label className="form__first-name email">
                      <p className="c-text-14 text-under">Comentarios / preguntas</p>
                      <textarea
                        className="input__email textarea"
                        type="email"
                        placeholder="johnsmith@gmail.com"
                        name="comment"
                        rows="4"
                        value={formData.comment}
                        onChange={handleInputChange}
                      />
                    </label>

                    <button type="submit" className="form__btn c-btn c-btn--green">
                      Enviar
                    </button>
                  </form>
                  <div className="block_r">
                    <p className="block_r__description">
                      ELE Barcelona tiene una ubicación súper céntrica en el elegante barrio del
                      Eixample, a solo 2 minutos a pie del Passeig de Gracia y a 10 minutos de Plaza
                      Catalunya.
                    </p>
                    <a
                      href="https://www.google.com.ua/maps/search/ELE+Barcelona+-+Calle+Valencia+275,+3er+piso+08009+Barcelona,+Espa%C3%B1a/@41.3947257,2.1629225,17z/data=!3m1!4b1?hl=ru&entry=ttu"
                      target="_blank"
                      className="item"
                    >
                      <img src={imgDir} alt="Dirección" />
                      <span className="item__inner">
                        <span className="item__title">Dirección:</span>
                        <span className="item__data">
                          ELE Barcelona - Calle Valencia 275, 3er piso 08009 Barcelona, España
                        </span>
                      </span>
                    </a>
                    <a href="tel:+34934875116" className="item">
                      <img src={imgTel} alt="" />
                      <span className="item__inner">
                        <span className="item__title">Teléfono:</span>
                        <span className="item__data">(+34) 934 875 116</span>
                      </span>
                    </a>
                    <a href="tel:+34634713933" className="item">
                      <img src={imgWha} alt="Whatsapp" />
                      <span className="item__inner">
                        <span className="item__title">Whatsapp:</span>
                        <span className="item__data">(+34) 634 713 933</span>
                      </span>
                    </a>
                    <a href="#" className="item">
                      <img src={imgClock} alt="" />
                      <span className="item__inner">
                        <span className="item__title">Horario de recepción:</span>
                        <span className="item__data">09.30 - 17.30, de lunes a viernes</span>
                      </span>
                    </a>
                    <a href="mailto:formacion@elebarcelona.com" className="item">
                      <img src={imgMail} alt="" />
                      <span className="item__inner">
                        <span className="item__title">Correo electrónico:</span>
                        <span className="item__data">formacion@elebarcelona.com</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >
      </div >
    </Layout >
  );
};

export default Cotacto;
